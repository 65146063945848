<template>
  <div class="add-card-view">
    <div class="form"></div>
    <div :class="['submit btn', { active: allowSubmit }]">绑定</div>
  </div>
</template>

<script>
import { post } from '@/axios'

export default {
  name: 'add-card',
  data() {
    return {
      form: {
        realName: '', // 姓名
        picName: '', // ? 银行图片
        cardName: '', // 银行名称
        cardCode: '', // 卡号
        phoneCode: '', // 手机号码
        area_prefix: '', // 区码
        vCode: '', // 验证码
        phone_flag: 'addbank_'
      },
      submitting: false
    }
  },
  async created() {
    await this.getAreaCodes()
  },
  methods: {
    async getAreaCodes() {
      await this.$store.dispatch('getAreaCodes')
      console.log('areaCodes: ', this.$store.state.areaCodes)
    },
    async submit() {
      try {
        const mapStr = JSON.stringify({ ...this.form })
        const { code, data, info } = await post(
          'member_card_addBankCard.do',
          this.Qs.stringify({ mapStr })
        )

        if (code === 'fail') {
          this.$message.error(info, 2)
        } else {
          const { msg, flag } = JSON.parse(data)
          // flag='1'
          // msg='驗證碼已失效，請重新發送！'
        }
        this.submitting = true
      } catch {
      } finally {
        this.submitting = false
      }
    }
  },
  computed: {
    rows() {
      return [
        { label: '持卡人', value: this.form.realName },
        { label: '开户银行', value: this.form.cardName },
        { label: '银行卡号', value: this.form.cardCode },
        { label: '', value: this.form.picName },
        { label: '', value: this.form.area_prefix },
        { label: '手机号码', value: this.form.phoneCode },
        { label: '短信验证码', value: this.form.vCode }
      ]
    },
    allowSubmit() {
      return true
    }
  }
}
</script>

<style lang="scss" scoped>
.add-card-view {
  .form {
  }
}
</style>
